import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import elect from './elec.jpg';
import shop from './shop.jpg';

import carrot from './carrot.jpg';
import bambou from './bambou.jpg';
import banane from './banane.jpg';
import chene from './chene.jpg';

import maryse from './maryse.jpg';
import passoir from './passoir.jpg';
import fourchette from './fourchette.jpg';
import rapeuse from './rapeuse.jpg';
import tirebouchon from './tirebouchon.jpg';

import coq from './coq.jpg';
import jak from './jak.jpg';

import enluminure from './enluminure.jpg';
import feux from './feux.jpg';
import enseigne from './enseigne.jpg';
import poste from './poste.jpg';


import Cookies from 'js-cookie';

const event = (event) => {
    console.log("event", event)
    fetch(`https://realityquest.city/elodie.php?event=${event}`)
        .then(response => response.json())
        .then(data => console.log(data));
}


const ElectPhoto = ({setIndex}) => { //0
    useEffect(() => {
        event("Start")
    }, [])
    return (
        <div>
            {/*href to open image*/}
            <a href={elect} target="_blank">
                <img src={elect} style={{width: "100%"}}/>
            </a>
            <div className={"explain"}>Click to zoom</div>

            <div className="next">
                <button onClick={() => setIndex(1)}>Next</button>
            </div>
        </div>
    )
}

const ElectPuzzle = ({setIndex}) => { //0

    //CURE 74201P0012

    const [indexes, setIndexes] = useState([0, 1, 2, 3, 1, 2, 3, 2, 3])
    // const [indexes, setIndexes] =                useState([7,4,2,0,1,0,0,1,1])
    console.log("indexes", indexes)
    console.log("Cookies.get(\"ElectPuzzle\")", Cookies.get("ElectPuzzle"))

    const rows = []

    const chars = [
        [" ", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J"], // 7
        [" ", "I", "L", "N", "M",], // 4
        ["🕛", "🕐", "🕑", "🕒", "🕓", "🕔"], // 2
        ["🔥", "🧘‍♂️", "👯‍♂️", "👨‍👨‍👧", "👨‍👩‍👧‍👧"], // 0
        ["🌑", "🌒", '🌓', '🌔', '🌕'], // 1
        ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"], // 0
        ["👌", "👍", "✌️", "🤟"], //0
        ["◻️", "⚀", "⚁", "⚂", "⚃", "⚄", "⚅"], //1
        [" ", "⚫", "│", "▲", "◼️", "⬟", "⬢"], //2
    ]


    const win = (indexes.join("") === "742010012" || Cookies.get("ElectPuzzle"))

    useEffect(() => {
        // persist ElectPuzzle in cookies
        if (win) {
            console.log("win!!!")
            Cookies.set("ElectPuzzle", true, {expires: 365})
            event("ElectPuzzle")
        }

    }, [win])


    "CURE-".split("").forEach((letter) => {
        rows.push(
            <span>
                {letter}
            </span>
        )
    })

    const increaseLetter = (index) => {
        const newLetters = [...indexes]
        if (newLetters[index] === chars[index].length - 1) {
            newLetters[index] = 0
        } else {
            newLetters[index]++
        }
        setIndexes(newLetters)
    }

    for (let i = 0; i < 5; i++) {
        console.log(i)
        rows.push(
            <span onClick={() => increaseLetter(i)}>
                {chars[i][indexes[i]]}
            </span>
        )
    }
    rows.push(
        <span>
                P
            </span>
    )

    for (let i = 5; i < 9; i++) {
        rows.push(
            <span onClick={() => increaseLetter(i)}>
                {chars[i][indexes[i]]}
            </span>
        )
    }

    return (
        <div>
            <div style={{fontSize: "30px"}} className="ElectPuzzle">
                {rows}
            </div>

            <div className="next">
                <button onClick={() => setIndex(0)}>Prev</button>
                {win && <button onClick={() => setIndex(2)}>Next</button>}
            </div>
        </div>
    )
}


const ShopPhoto = ({setIndex}) => { //0
    return (
        <div>
            {/*href to open image*/}
            <a href={shop} target="_blank">
                <img src={shop} style={{width: "100%"}}/>
            </a>
            <div className={"explain"}>Click to zoom</div>

            <div className="next">
                <button onClick={() => setIndex(1)}>Prev</button>
                <button onClick={() => setIndex(3)}>Next</button>
            </div>
        </div>
    )
}

const ShopPuzzle = ({setIndex}) => { //0

    const [indexes, setIndexes] = useState([0, 0, 0, 0])
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    // const [indexes, setIndexes] =                useState([7,4,2,0,1,0,0,1,1])
    console.log("indexes", indexes)
    console.log("Cookies.get(\"ShopPuzzle\")", Cookies.get("ShopPuzzle"))

    const chars = [
        [enluminure, feux, enseigne, poste], // 2
        ["yadnom", "yadseut", "yadsendew", "yadsruht", "yadirf", "yadrutas", "yadnus"], // 5
        [bambou, banane, chene, carrot], // 3
        [passoir, rapeuse, maryse, fourchette, tirebouchon], // 2
    ]


    useEffect(() => {
        if (Cookies.get("ShopPuzzle")) {
            setSuccess(true)
        }
    })

    useEffect(() => {
        if (success ) {
            console.log("win!!!")
            Cookies.set("ShopPuzzle", true, {expires: 365})
            event("ShopPuzzle")
        }
    }, [success])

    // const rows = []

    const increaseLetter = (index) => {
        const newLetters = [...indexes]
        if (newLetters[index] === chars[index].length - 1) {
            newLetters[index] = 0
        } else {
            newLetters[index]++
        }
        setIndexes(newLetters)
    }

    const rows = [(row_index) => {
        return (<span onClick={() => increaseLetter(row_index)}>
                <img src={chars[row_index][indexes[row_index]]} style={{width: "100%"}}/>
            </span>)
    }, (row_index) => {
        return (<span onClick={() => increaseLetter(row_index)}>
        {chars[row_index][indexes[row_index]]}
        </span>)
    }, (row_index) => {
        return (<span onClick={() => increaseLetter(row_index)}>
                <img src={chars[row_index][indexes[row_index]]} style={{width: "100%"}}/>
            </span>)
    }, (row_index) => {
        return (<span onClick={() => increaseLetter(row_index)}>
                <img src={chars[row_index][indexes[row_index]]} style={{width: "100%"}}/>
            </span>)
    }].map((f, i) => f(i))

    return (
        <div>
            <div style={{fontSize: "30px"}} className="ShopPuzzle">
                {rows}
            </div>
            {!success && <div className="next">
                <button onClick={() => {
                    if (indexes.join("") === "2332") {
                        setSuccess(true)
                    } else {
                        setError(true)
                    }
                }}>Valider
                </button>
                {error && <div style={{color: "red"}}>Faux</div>}
            </div>}

            <div className="next">
                <button onClick={() => setIndex(2)}>Prev</button>
                {success && <button onClick={() => setIndex(4)}>Next</button>}
            </div>
        </div>
    )
}


const PhotoCoq = ({setIndex}) => { //0
    return (
        <div>
            {/*href to open image*/}
            <a href={coq} target="_blank">
                <img src={coq} style={{width: "100%"}}/>
            </a>
            <div className={"explain"}>Click to zoom</div>

            <div className="next">
                <button onClick={() => setIndex(3)}>Prev</button>
                <button onClick={() => setIndex(5)}>Next</button>
            </div>
        </div>
    )
}

const CoqPuzzle = ({setIndex}) => { //0

    const [text, setText] = useState("")


    console.log("text", text)
    console.log("Cookies.get(\"CoqPuzzle\")", Cookies.get("CoqPuzzle"))

    const win = (text === "colombiere" || Cookies.get("CoqPuzzle"))

    useEffect(() => {
        if (win) {
            console.log("win!!!")
            Cookies.set("CoqPuzzle", true, {expires: 365})
            event("CoqPuzzle")
        }
    })

    return (
        <div>
            <div style={{
                fontSize: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }} className="ShopPuzzle">

                {/*    Max character 10 , center*/}
                <input type="text"
                       value={text} onChange={(e) => {
                    let value = e.target.value;
                    if (value.length > 10)
                        value = value.slice(0, 10)
                    value = value.toLowerCase().trim()
                    setText(value)
                }}
                       style={{
                           width: "90%",
                           fontSize: "30px",
                           textAlign: "center",
                           marginTop: "20px",
                           marginBottom: "20px"
                       }}

                />
                <div>G-7-9</div>
                <div>G-12-16</div>
                <div>G-5-5</div>
                <div>D-9-12</div>
                <div>G-10-1</div>
                <div>G-3-1</div>
                <div>D-1-2</div>
                <div>D-2-2</div>
                <div>D-3-1</div>
                <div>D-4-2</div>
            </div>

            <div className="next">
                <button onClick={() => setIndex(4)}>Prev</button>
                {win && <button onClick={() => setIndex(6)}>Next</button>}
            </div>
        </div>
    )
}


const PhotoJak = ({setIndex}) => { //0
    return (
        <div>
            {/*href to open image*/}
            <a href={jak} target="_blank">
                <img src={jak} style={{width: "100%"}}/>
            </a>
            <div className={"explain"}>Click to zoom</div>

            <div className="next">
                <button onClick={() => setIndex(5)}>Prev</button>
                <button onClick={() => setIndex(7)}>Next</button>
            </div>
        </div>
    )
}

const PuzzleJak = ({setIndex}) => { //0

    const [cheveux, setCheveux] = useState(0)
    const [doigts, setDoigts] = useState(0)
    const [maillon, setMaillon] = useState(0)


    console.log("cheveux", cheveux)
    console.log("doigts", doigts)
    console.log("maillon", maillon)
    console.log("Cookies.get(\"PuzzleJak\")", Cookies.get("PuzzleJak"))

    const win = (Cookies.get("PuzzleJak") ||
        (cheveux > 35 && cheveux < 45
            && doigts === 10
            && maillon > 25 && maillon < 35))

    useEffect(() => {
        if (win) {
            console.log("win!!!")
            Cookies.set("PuzzleJak", true, {expires: 365})
            event("PuzzleJak")
        }
    })

    return (
        <div>
            <div style={{
                fontSize: "30px",
                lineHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }} className="PuzzleJak">
                <div><b>/alfabɛ/ /fɔnetik/</b></div>
                <div>
                    <label>/kɔ̃te/ /ʃəvø/</label>
                    <input type="number" min="0" max="100" value={cheveux} onChange={(e) =>
                        setCheveux(parseInt(e.target.value))}/>
                </div>
                <div>
                    <label>/kɔ̃te/ /dwa/</label>
                    <input type="number" min="0" max="20" value={doigts}
                           onChange={(e) => setDoigts(parseInt(e.target.value))}/>
                </div>
                <div>
                    <label>/kɔ̃te/ /majɔ̃/</label>
                    <input type="number" min="0" max="100" value={maillon}
                           onChange={(e) => setMaillon(parseInt(e.target.value))}/>
                </div>
            </div>

            <div className="next">
                <button onClick={() => setIndex(6)}>Prev</button>
                {win && <button onClick={() => setIndex(8)}>Next</button>}
            </div>
        </div>
    )
}


const End = ({setIndex}) => { //0
    return (
        <div style={{fontSize: "30px", lineHeight: "35px"}}>
            <h1>Bravo !!!</h1>
            <div style={{textAlign: "left", margin: "5px", wordBreak: "break-word", whiteSpace: "pre-wrap"}}>
                En <b>souhaitant</b> très fort arriver quelque part sans connaître le chemin, cela ne marche souvent
                pas.<br/><br/>
                Il vaut mieux connaître les coordonnées du point d'arriver. <br/><br/>
                Cela dit pour faire <b>éclore</b> les fleurs y penser très fort
                suffit si vous êtes un lutin et savez <b>rester</b> maître des éléments du jardin.<br/>
                <br/>
                Pensez bien toutefois que, comme pour les <b>coordonnées</b>, plusieurs formulations sont possibles pour faire
                pousser les plantes, il faut parfois essayer des variations originales pour y parvenir.
            </div>

            <div className="next">
                <button onClick={() => setIndex(7)}>Prev</button>
            </div>
        </div>
    )
}


const pages = [ElectPhoto, ElectPuzzle, ShopPhoto, ShopPuzzle, PhotoCoq, CoqPuzzle, PhotoJak, PuzzleJak, End]

function App() {

    const [index, setIndex] = useState(0);
    // const [index, setIndex] = useState(1);

    const Current = pages[index];

    return (
        <div className="App">
            <Current {...{setIndex}} />
        </div>
    );
}

export default App;
